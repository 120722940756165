<template>
  <div id="lom-events-create">
    <h1>{{ PageTitle }}</h1>
    <h4>Student Scholarship Create</h4>
    <br />
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-tour name="myTour" :steps="TourSteps" :options="TourOptions"></v-tour>
    <v-card elevation="1" color="#F5F5F5">
      <v-card-text>
        <v-container>
          <v-card elevation="3" color="#3a67b1" v-if="MemeberFullname != ''">
            <v-card-text>
              <v-row wrap>
                <v-col
                  align="center"
                  cols="12"
                  md="12"
                  class="mycard-title-2 fsize-5 fcolor-1"
                >
                  <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
                  <p>Welcome {{ MemeberFullname }},</p>
                  <p>{{ CurrentDesignation }}, {{ LomName }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="backPage"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-arrow-left-circle </v-icon> &nbsp; Back
              </v-btn>
              <v-btn
                @click.prevent="refreshPageData"
                color="#EDBE38"
                elevation="30"
                shaped
                class="font-size-h6 fsize-3 mr-3 my-3 white--text"
              >
                <v-icon dark> mdi-refresh </v-icon> &nbsp; Refresh
              </v-btn>
            </v-col>
          </v-row>

          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>

          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Student Details&nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> </span>
                  </template>
                  <span class="mycard-text2">
                    Fill in the date and time of the event and the venue
                    details.
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="StudentName"
                      :rules="StudentNameRules"
                      outlined
                      dense
                      hint="Enter Student Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Gender
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="StudentGenderOptions"
                      :rules="StudentGenderRules"
                      v-model="StudentGender"
                      persistent-hint
                      dense
                      clearable
                      required
                      outlined
                      hint="Select Student Gender"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Special Category
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="SpecialCategoryOptions"
                      :rules="SpecialCategoryRules"
                      v-model="SpecialCategory"
                      persistent-hint
                      dense
                      clearable
                      required
                      outlined
                      hint="Select Special Category"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Type
                      </h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="StudentTypeItems"
                      :rules="StudentTypeRules"
                      v-model="StudentType"
                      persistent-hint
                      dense
                      clearable
                      required
                      outlined
                      hint="Select Student Type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Class Name
                      </h6>
                    </label>
                    <v-autocomplete
                      :items="StudentClassNameOptions"
                      v-model="StudentClassName"
                      :rules="StudentClassNameRules"
                      item-text="text"
                      item-value="value"
                      dense
                      clearable
                      required
                      outlined
                      hint="Select Student Class Name"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Total Mark
                      </h6>
                    </label>
                    <v-text-field
                      v-model="TotalMark"
                      :rules="TotalMarkRules"
                      outlined
                      dense
                      hint="Enter Student Total Mark"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Percentage
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Percentage"
                      :rules="percentageRules"
                      outlined
                      dense
                      hint="Enter Student Percentage"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step6">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Student Contact
                        Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="StudentContactNumber"
                      :rules="StudentContactNumberRules"
                      outlined
                      dense
                      hint="Enter Student Contact Number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Upload Student Photo
                      </h6>
                    </label>
                    <v-file-input
                      truncate-length="10"
                      accept="image/jpeg"
                      outlined
                      dense
                      hint="Upload Student Photo"
                      v-model="StudentImage"
                      prepend-icon="mdi-camera"
                      :clearable="false"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                    <div class="actions">
                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(true, false)"
                        >
                          <v-icon>fa fa-caret-right</v-icon>
                        </v-btn>
                      </b-button-group>
                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(false, true)"
                        >
                          <v-icon>fa fa-caret-up</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(90)"
                        >
                          <v-icon>fa fa-redo</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(-90)"
                        >
                          <v-icon>fa fa-undo</v-icon>
                        </v-btn>
                      </b-button-group>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" lg="4" md="4">
                    <cropper
                      ref="cropper"
                      :src="MemberImage.ImageUrl"
                      :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                        aspectRatio: 1,
                        previewClass: 'preview',
                      }"
                      :stencil-size="{
                        width: 300,
                        height: 300,
                      }"
                      image-restriction="stencil"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Parents Details &nbsp;
                <v-tooltip right max-width="60%" allow-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> </span>
                  </template>
                  <span>
                    Choose the 100% efficiency criteria if this event is
                    eligible
                  </span>
                </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Parent Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ParentName"
                      :rules="ParentNameRules"
                      outlined
                      dense
                      hint="Enter Parent Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Parent Occupation
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ParentOccupation"
                      :rules="ParentOccupationRules"
                      outlined
                      dense
                      hint="Enter Parent Occupation"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Parents Contact
                        Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="ParentContactNumber"
                      :rules="ParentContactNumberRules"
                      outlined
                      dense
                      hint="Enter Parent Contact Number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Parents Annual Income
                        <br />
                        <small>(The income should be in number)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="AnnualIncome"
                      :rules="AnnualIncomeRules"
                      outlined
                      dense
                      hint="Enter Parent Annual Income"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                <span>School Details &nbsp; </span>
                <v-tooltip right max-width="60%" allow-overflow> </v-tooltip>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span>Principal Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="PrincipalName"
                      :rules="PrincipalNameRules"
                      outlined
                      dense
                      hint="Enter Principal Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span>School Address
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SchoolAddress"
                      :rules="SchoolAddressRules"
                      outlined
                      dense
                      hint="Enter School Address"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span>School Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SchoolName"
                      :rules="SchoolNameRules"
                      outlined
                      dense
                      hint="Enter School Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> School Contact Number
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SchoolCantactNumber"
                      :rules="SchoolCantactNumberRules"
                      outlined
                      dense
                      hint="Enter School Contact Number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4" id="step7">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        Sponsor Name (Optional)
                      </h6>
                    </label>
                    <v-text-field
                      v-model="SponsorName"
                      :rules="SponsorNameRules"
                      outlined
                      dense
                      hint="Enter Sponsor Name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Student Attachment Images &nbsp;
              </v-card-title>
              <v-card-text>
                <p class="notes-text1">Kindly note:</p>
                <ol class="notes-text1">
                  <li>Only JPEG, PNG file types are allowed to be uploaded.</li>
                  <li>Max file size per image should not exceed 1 MB.</li>
                </ol>
                <v-row class="file-input-row" no-gutters>
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <h6 class="mycard-text1 fsize-3">
                      <span class="text-danger">*</span> Annual Income Letter
                      image
                    </h6>
                    <v-file-input
                      accept="image/png, image/jpeg"
                      outlined
                      v-model="AnnualIncomeLetter"
                      dense
                      hint="Upload Annual Income Letter"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="4" md="4" lg="4">
                    <h6 class="mycard-text1 fsize-3">
                      <span class="text-danger">*</span> Student Mark image
                    </h6>
                    <v-file-input
                      accept="image/png, image/jpeg"
                      v-model="StudentMarkImage"
                      outlined
                      dense
                      hint="Upload Student Mark Image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <h6 class="mycard-text1 fsize-3">
                      <span class="text-danger">*</span> Student Recommendation
                      image
                    </h6>
                    <v-file-input
                      accept="image/png, image/jpeg"
                      v-model="RecommendationLetter"
                      outlined
                      dense
                      hint="Upload Student Recommendation Letter"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br /><br />
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2">
                Student Bank Information &nbsp;
              </v-card-title>
              <v-container>
                <v-row wrap> </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6><span class="text-danger">*</span> IFSC Code</h6>
                    <v-row align="center" no-gutters>
                      <v-row align="center" no-gutters>
                        <v-col cols="auto">
                          <v-text-field
                            style="width: 330px"
                            v-model="IfscNumber"
                            :rules="IfscCodeRules"
                            :disabled="isFieldDisbaled"
                            placeholder="IFSC Code"
                            required
                            outlined
                            dense
                            hint="Enter IFSC Code"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="searchForm"
                            :loading="SearchFlag"
                            color="#8950FC"
                            class="white--text text-h6 ml-4 mb-5"
                            rounded
                          >
                            Search
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col
                        cols="auto"
                        style="margin-left: 70px; margin-bottom: 5px"
                      >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6><span class="text-danger">*</span> Account Number</h6>
                    <v-text-field
                      v-model="AccountNumber"
                      :rules="AccountNumberRules"
                      :type="show3 ? 'text' : 'password'"
                      placeholder="Bank Account Number"
                      required
                      outlined
                      dense
                      hint="Enter Bank Account Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6>
                      <span class="text-danger">*</span>Re-Enter Account Number
                    </h6>
                    <v-text-field
                      v-model="ReEnterAccountNumber"
                      :rules="ReEnterAccountNumberRules"
                      placeholder="Re-Enter Account Number"
                      required
                      outlined
                      dense
                      hint="Re-Enter Bank Account Number"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="FormFlag" style="width: 400px">
                    <v-card class="py-4" color="#f7cad0">
                      <v-row wrap class="mx-2">
                        <v-col cols="12" md="12" class="text-h6 bold--text">
                          Bank Name :
                          <span class="blue--text"> {{ BankName }}</span>
                        </v-col>
                      </v-row>
                      <v-row wrap class="mx-2">
                        <v-col cols="12" md="12" class="text-h6 bold--text">
                          Branch Name :
                          <span class="blue--text">
                            {{ BranchName }}</span
                          ></v-col
                        >
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span>Account Holder Name
                      </h6>
                    </label>
                    <v-text-field
                      v-model="HolderName"
                      :rules="HolderNameRules"
                      outlined
                      dense
                      hint="Enter Account Holder Name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-3">
                        <span class="text-danger">*</span> Passbook First Page
                        Image
                      </h6>
                    </label>
                    <v-file-input
                      accept="image/png, image/jpeg"
                      v-model="PassBookImage"
                      outlined
                      dense
                      hint="Upload Passbook First Page Image"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br /><br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <upload-progress
                  :ProgessStart="progessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <br />
                <v-btn
                  id="step12"
                  type="submit"
                  @click.prevent="confirmAlert(2)"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#EDBE38"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi-briefcase-check </v-icon> &nbsp; Save
                </v-btn>
                <v-btn
                  id="step12"
                  type="submit"
                  @click.prevent="confirmAlert(1)"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#5fc52e"
                  class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                >
                  <v-icon dark> mdi mdi-check </v-icon> &nbsp; Final Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      TooltipShow1: false,
      SpecialCategoryOptions: [
        { text: "--Select Status--", value: null },
        { text: "NA", value: 1 },
        { text: "Orphan", value: 2 },
        { text: "Specially abled", value: 3 },
        { text: "Single parent", value: 4 },
        { text: "Girl child", value: 5 },
      ],
      SpecialCategory: null,

      ScholarshipType: null,
      StudentTypeItems: [
        { text: "--Select Status--", value: "" },
        { text: "School", value: 1 },
        { text: "College", value: 2 },
      ],
      StudentType: "",
      StudentTypeRules: [
        (v) => !!v || "Student Type is required"
      ],

      StudentClassNameOptions: [
        { text: "--Select Status--", value: "" },
        { text: "9 Std", value: 9 },
        { text: "10 Std", value: 10 },
        { text: "11 Std", value: 11 },
        { text: "12 Std", value: 12 },
      ],
      StudentClassName: "",
      StudentClassNameRules: [],

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      OverlayLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      SearchResultFlag: false,
      ShowFormFlag: false,
      NewTitleFlag: true,
      NotesFlag: false,
      rows: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      show3: false,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,
      isFieldDisbaled: false,

      TabFlag3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      BankName: "",
      BranchName: "",

      AccountNumber: "",
      AccountNumber: "",
      ReEnterAccountNumber: "",

      AccountNumberRules: [],

      ReEnterAccountNumberRules: [
        (v) => v <= this.AccountNumber || "Not match.",
      ],

      Member: {},
      Order: {},
      MemberFlag: false,

      PenaltySubscription: [],

      image: {
        src: "media/images/white-user.png",
        type: null,
      },

      newimage: "",
      selectedImage: "",

      StudentImage: {},
      MemberImage: { src: null },

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      HolderNameRules: [],
      IfscCodeRules: [],
      SchoolCantactNumberRules: [],
      SchoolNameRules: [],
      SchoolAddressRules: [],

      PrincipalNameRules: [],

      AnnualIncomeRules: [],
      ParentOccupationRules: [],

      StudentContactNumberRules: [],

      progessStart: 0,
      uploadPercentage: 0,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      StudentName: "",
      StudentNameRules: [
        (v) => !!v || "Student name is required"
      ],

      StudentGender: "",
      StudentGenderRules: [
        (v) => !!v || "Gender is required"
      ],

      SpecialCategory: "",
      SpecialCategoryRules: [
        (v) => !!v || "Special Category is required"
      ],

      ParentName: "",
      ParentNameRules: [],

      TotalMark: "",
      TotalMarkRules: [],

      Percentage: "",
      PercentageRules: [],

      StudentContactNumber: "",
      StudentContactNumberRules: [
        // (v) => !!v || "Student Contact Number is required",
        // (v) => /^\d{10}$/.test(v) || "Phone number must be exactly 10 digits",
      ],

      ParentOccupation: "",
      ParentOccupationRules: [],

      ParentContactNumber: "",
      ParentContactNumberRules: [
        // (v) => !!v || "Parent Contact Number is required",
        // (v) => /^\d{10}$/.test(v) || "Phone number must be exactly 10 digits",
      ],

      PrincipalName: "",
      PrincipalNameRules: [],

      SchoolAddress: "",
      SchoolAddressRules: [],

      SchoolName: "",
      SchoolNameRules: [],

      SchoolCantactNumber: "",
      SchoolCantactNumberRules: [
        // (v) => !!v || "Student Contact Number is required",
        // (v) => /^\d{10}$/.test(v) || "Phone number must be exactly 10 digits",
      ],
      SponsorName: "",
      SponsorNameRules: [],
      HolderName: "",
      HolderNameRules: [],

      IfscNumber: "",
      IfscNumberRules: [],
      AccountNumber: "",
      AccountNumberRules: [],
      AnnualIncome: "",
      AnnualIncomeRules: [],

      RecommendationLetter: {},
      AnnualIncomeLetter: {},
      StudentMarkImage: {},
      StudentImage: {},
      PassBookImage: {},
      PageTitle: "",

      uploadedImages: [],
      TotalImages: 0,

      PageInfo: [],
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        var YearName = tr.CurrentJciYearName;
        this.YearName = YearName;

        var MonthCode = this.$route.query.month;
        MonthCode = MonthCode == (null || undefined) ? 0 : MonthCode;
        console.log({ MonthCode });
        this.MonthCode = MonthCode;

        var tempDate1 = YearName + "-" + MonthCode + "-01";
        console.log({ tempDate1 });

        var tempDate2 = new Date(tempDate1);
        console.log({ tempDate2 });
        var MonthName = tempDate2.toLocaleString("en-us", { month: "long" });
        console.log({ MonthName });
        this.MonthName = MonthName;

        var MrfStartDate = tr.MrfStartDate;
        console.log({ MrfStartDate });

        this.FromDateMin = MrfStartDate;
        this.ToDateMin = MrfStartDate;

        var MrfEndDate = tr.MrfEndDate;
        console.log({ MrfEndDate });

        this.FromDateMax = MrfEndDate;
        this.ToDateMax = MrfEndDate;

        this.pageData();
        this.getJciYearCodeOptions();
      }
    },
    IfscNumber: function () {
      console.log("watch IfscNumber");
      var IfscNumberValue = this.IfscNumber;
      console.log({ IfscNumberValue });
      // this.isFieldDisbaled = true;
    },
  },
  created() {
    this.MemberImage.src = this.currentPhoto;
  },
  methods: {
    initialize() {
      console.log("initialize called");
      var options = this.cropImageOptions;
      console.log("options=" + JSON.stringify(options));
      // this.$refs.cropper.setDefaults(options);
      this.$refs.cropper.setAspectRatio(options.aspectRatio);
      // this.$refs.cropper.setCropBoxData(this.cropBoxData);
      this.$refs.cropper.setDragMode("move");
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.StudentImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.MemberImage.src) {
            URL.revokeObjectURL(this.MemberImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.MemberImage = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.MemberImage = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.StudentImage = {};
          break;

        default:
          break;
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    loadImage(event) {
      console.log("loadImage is called");
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setImage(e) {
      console.log("setImage called");
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          var options = this.cropImageOptions;
          console.log("options=" + JSON.stringify(options));
          this.$refs.cropper.initialize();
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    download() {
      if (this.cropImg) {
        var extention = this.fileExtension;
        var href = this.cropImg;
        var link = document.createElement("a");
        link.addEventListener(
          "click",
          function () {
            link.href = href;
            link.download = "cropper-img." + extention;
            // console.log(link.download);
          },
          false
        );
        link.click();
      } else {
        alert("Please, crop image before download it");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetForm() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    resetMember() {
      console.log("resetMember called");
      this.LomCode = "";
      this.Member = {
        MemberId: "",
        PrimaryMobile: "",
        EmailId: "",
      };
      this.MemberFlag = false;
    },
    resetSubscription() {
      this.SearchResultFlag = false;
      this.rows = [];
      this.PenaltySubscription = [];
    },
    refreshPageData() {
      console.log("pageData called");
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        // Module: "lom_events",
        // Action: "create",
        Module: "student_scholarship",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("refreshPageData called");

      var ZoneName = this.$session.get("ZoneName");
      ZoneName = ZoneName == (null || undefined) ? "" : ZoneName;
      console.log({ ZoneName });
      this.ZoneName = ZoneName;

      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log({ ZoneCode });
      this.ZoneCode = ZoneCode;

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log({ LomCode });
      this.LomCode = LomCode;

      var LomName = this.$session.get("LomName");
      LomName = LomName == (null || undefined) ? "" : LomName;
      console.log({ LomName });
      this.LomName = LomName;

      var CurrentDesignation = this.$session.get("CurrentDesignation");
      CurrentDesignation =
        CurrentDesignation == (null || undefined) ? "" : CurrentDesignation;
      console.log({ CurrentDesignation });
      this.CurrentDesignation = CurrentDesignation;

      var MemeberFullname = this.$session.get("MemeberFullname");
      MemeberFullname =
        MemeberFullname == (null || undefined) ? "" : MemeberFullname;
      console.log("MemeberFullname=" + MemeberFullname);
      this.MemeberFullname = MemeberFullname;
    },
    confirmAlert(status) {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var htmlTxt = "";
        var title = "";
        if (status == 1) {
          title = "Final Submit?";
          htmlTxt +=
            "<h6> You are about to final submit this application. If you click yes, you cannot edit it anymore. Do you want to continue?</h6>";
        } else {
          title = "Save?";
          htmlTxt +=
            "<h6> You are partially saving this application. You can edit it till the deadline. Do you want to continue?</h6>";
        }

        Swal.fire({
          title: title,
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.getCroppedImage(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    getCroppedImage(status) {
      console.log("getCroppedImage is called");
      console.log("status", status);

      const validate1 = this.$refs.form1.validate();
      console.log("validate1 =", validate1);

      if (validate1) {
        let StudentData = {
          StudentName: this.StudentName,
          SpecialCategory: this.SpecialCategory,
          TotalMark: this.TotalMark,
          Percentage: this.Percentage,
          StudentType: this.StudentType,
          StudentGender: this.StudentGender,
          ParentName: this.ParentName,
          StudentClassName: this.StudentClassName,
          StudentContactNumber: this.StudentContactNumber,
          ParentOccupation: this.ParentOccupation,
          ParentContactNumber: this.ParentContactNumber,
          PrincipalName: this.PrincipalName,
          SchoolAddress: this.SchoolAddress,
          SchoolCantactNumber: this.SchoolCantactNumber,
          SponsorName: this.SponsorName,
          HolderName: this.HolderName,
          IfscNumber: this.IfscNumber,
          AccountNumber: this.AccountNumber,
          AnnualIncome: this.AnnualIncome,
          SchoolName: this.SchoolName,
          BankName: this.BankName,
          BranchName: this.BranchName,
          UserInterface: status,
        };

        console.log({ StudentData });

        let upload = new FormData();
        upload.append("UserInterface", status);
        upload.append("Form", JSON.stringify(StudentData));

        // Append other files
        if (this.RecommendationLetter) {
          upload.append("RecommendationLetter", this.RecommendationLetter);
        }
        if (this.AnnualIncomeLetter) {
          upload.append("AnnualIncomeLetter", this.AnnualIncomeLetter);
        }
        if (this.StudentMarkImage) {
          upload.append("StudentMarkImage", this.StudentMarkImage);
        }
        if (this.PassBookImage) {
          upload.append("PassBookImage", this.PassBookImage);
        }

        var file = this.StudentImage;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            upload.append("StudentImage", blob, FileName);
            this.submitForm(upload);
          }, FileType);
        } else {
          upload.append("StudentImage", null);
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", output, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");
      console.log({ upload });

      const validate1 = this.$refs.form1.validate();
      console.log("validate1 =", validate1);

      var message = "";

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/student/store";

        console.log(
          "server_url =",
          server_url,
          "token =",
          token,
          "add_url =",
          add_url
        );

        this.OverlayLoadingFlag = true;

        const thisIns = this;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then((response) => {
            console.log("response =", response);

            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;
            thisIns.progessStart = 0;

            const { flag, output } = response.data;
            console.log("output =", output, "flag =", flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.$router.push("/student-scholarship/list");
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch((error) => {
            console.error("error =", error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", output, false);
      }
    },
    searchForm() {
      console.log("searchForm called");
      this.SearchFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/get-bank-details";
      var upload = {
        IfscCode: this.IfscNumber,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          thisIns.SearchFlag = false;
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SubmitFlag = false;

          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          if (flag == 1) {
            thisIns.BankName = records.BankName;
            thisIns.BranchName = records.BranchName;
            console.log("BankName" + thisIns.BankName);
            console.log("BranchName" + thisIns.BranchName);

            thisIns.FormFlag = true;
            console.log("FormFlag" + thisIns.FormFlag);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.getJciYearCodeOptions();
  },
  mounted: function () {},
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#lom-events-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  #faculty-table {
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      font-size: 12px;
      color: red;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 15px;
    }
  }
  .mycard-head-title {
    // padding-bottom: 0;
    font-size: 20px;
    font-family: sans-serif;
    padding: 20px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-1 {
    font-family: sans-serif;
    padding: 10px;
    text-align: left;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-title-2 {
    font-family: "Roboto", serif;
    padding: 10px;
    text-align: center;
    word-break: break-word;
    line-height: normal;
    // text-transform: capitalize;
  }
  .mycard-text1 {
    font-family: sans-serif;
    // text-transform: capitalize;
  }
  .mycard-text2 {
    font-size: 20px;
    font-family: "Merriweather", serif;
    color: red;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
  .mycard-text4 {
    font-size: 20px;
    font-family: "EB Garamond", serif;
    font-weight: bold;
    font-style: italic;
  }
  .mycard-text5 {
    font-size: 20px !important;
    font-family: "Roboto", serif;
    color: white;
  }
  .mycard-head-subtitle {
    padding-bottom: 0;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: sans-serif;
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-family: sans-serif;
    font-size: 14px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .note-text1 {
    margin-left: 2rem;
    font-size: 14px;
    padding: 5px;
  }
  .fsize-1 {
    font-size: 12px !important;
  }
  .fsize-2 {
    font-size: 14px !important;
  }
  .fsize-3 {
    font-size: 16px !important;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .fsize-5 {
    font-size: 20px !important;
  }
  .fcolor-1 {
    color: #fff !important;
  }
  .bg-color-1 {
    background-color: #3a67b1;
  }
  .bg-color-2 {
    background-color: #0097d7;
  }
  .bg-color-3 {
    background-color: #edbe38;
  }
  .bg-color-4 {
    background-color: #56bda3;
  }
}
</style>